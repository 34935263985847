import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "About me",
  "icon": "M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`These days I'm exploring distributed ledgers, doing R&D on the decentralized Web and e-health at my startup `}<a parentName="p" {...{
        "href": "https://fermi.xyz"
      }}>{`Fermi.xyz`}</a>{`, while advising other companies and having `}<a parentName="p" {...{
        "href": "https://fermi.xyz/retreats"
      }}>{`meaningful conversations`}</a>{`. Previously I was director of software solutions (US & EU) at iHealth, a ~300 people digital health and wearables company, where I managed products and teams of engineers and product managers to build B2B telemedicine web apps and mobile apps in Silicon Valley, Europe and Singapore.
Before that I studied Product Management at Stanford, built startups, spent a cool summer at NASA Ames doing the GSP at Singularity University, did academic research in Semantic Web and bioinformatics, built an award-winning web portal on science and tech when I was 14 and other things you can find out over at `}<a parentName="p" {...{
        "href": "https://likedin.com/in/maxscience"
      }}>{`LinkedIn`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/maxscience"
      }}>{`Twitter`}</a>{`.`}</p>
    <p>{`Some fun facts: 👨‍🎓 studied and worked in four countries, 🛩 traveled in over 30, 🏛 said no to a funded MBA at Oxford and PhD in Computer Science at Imperial College London to do startups, 🍏 Apple power user since the beginning (owned the Macintosh LC, Performa, PowerMac G3, G4, G5, iBook, iMac, PowerBook G3, G4, Mac Pro, Macbook Pro, iPod, etc.), 🚁 flew an helicopter out of SQL airport (after Oracle's Larry Ellison and the database query language ¯`}{`\\`}{`_`}{`(ツ)`}{`_`}{`/¯ SV), 🏎 drove on part of the Monaco F1 race track with my car & other things that sound cool but I don't actually have a lavish lifestyle. 👨‍👩‍👧‍👦 I value rare human connections, friendships and relationships, ethics, imagination and curiosity. Feel free to `}<a parentName="p" {...{
        "href": "https://max.science.cx/contact"
      }}>{`email me`}</a>{`!`}</p>
    <h4>{`Interests 👨‍💻`}</h4>
    <p>{`Everyday I learn more about (random order):`}</p>
    <ul>
      <li parentName="ul">{`🕸 Startups, management, effective organizations and how people collaborate in teams`}</li>
      <li parentName="ul">{`💻 Products & Product Management, technology, design`}</li>
      <li parentName="ul">{`🔭 (Open) Science: mainly Computer Science, Biology, Physics`}</li>
      <li parentName="ul">{`🔬 Engineering: mainly web development, architectures, Machine Learning`}</li>
      <li parentName="ul">{`🏰 History, history of science, Philosophy, biographies`}</li>
      <li parentName="ul">{`🥑 Health, nutrition and fitness`}</li>
      <li parentName="ul">{`💹 Economics, ethics and value`}</li>
      <li parentName="ul">{`⛓ Edge technologies: blockchain, edge computing, no BS 'deep tech', etc.`}</li>
    </ul>
    <h5>{`Some other interests ⛵`}</h5>
    <ul>
      <li parentName="ul">{`🌍 Photography, movies, home theatre & audiophile setups, exploring new topics & places`}</li>
      <li parentName="ul">{`🚴‍♂️ Sailing, driving (mostly Subaru. I also like the '19 Mazda MX-5/Fiat Abarth 124), skiing, rowing, biking, hiking, archery`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      